<template>
  <div>
    <div v-if="!detailsProps.id">
      <PageLoader></PageLoader>
    </div>
    <div v-else class="details-holder">
      <div class="back-arrow" style="cursor: pointer;">
        <img
          @click="backTo"
          src="../assets/images/thickbackarrow.svg"
          alt="back"
        />
        <span style="cursor: pointer;" @click="backTo">Property</span>
      </div>

      <div class="property-info">
        <div class="row top-row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-4 col-sm-12 col-12">
                <div>
                  <img
                    v-if="detailsProps.oneimageurl == null || detailsProps.oneimageurl == ''"
                    style="width: 100%;"
                    src="../assets/images/property-image-placeholder.svg"
                    alt="property"
                  />
                  <img
                    v-else
                    style="width: 100%;"
                    :src="detailsProps.oneimageurl"
                    alt="property"
                  />
                </div>
              </div>
              <div class="col-md-8">
                <div class="prop-details">
                  <h4>{{ detailsProps.title }}</h4>
                  <h6>{{ detailsProps.city }} {{ detailsProps.state }}</h6>
                  <h3>
                    ₦{{ Number(detailsProps.price).toLocaleString() }}/Annually
                  </h3>
                  <small>Rent financing application in view</small>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-4"
            v-if="
              detailsProps.rentFinancingObject.applied_date != null &&
                detailsProps.rentFinancingObject.approvedforrentfinancing == '0'
            "
          >
            <div class="countdown">
              <h4>Verification Countdown</h4>
              <small>{{ displayDays }}</small>
              <span>Day<span v-if="displayDays > 1">s</span></span>
              <small>{{ displayHours }}</small>
              <span>Hour<span v-if="displayHours > 1">s</span></span>
              <small>{{ displayMinutes }}</small>
              <span>Min<span v-if="displayMinutes > 1">s</span></span>
              <small>{{ displaySeconds }}</small>
              <span>Sec</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="financing-documents-submition"
        v-if="detailsProps.rentFinancingObject.applied_date != null"
      >
        <div class="financing-header">
          <h4>Rent Financing</h4>
          <div class="thick-line">
            <div
              class=""
              :class="[counter >= 1 ? 'active-thickline' : '']"
            ></div>
            <div
              class=""
              :class="[counter >= 2 ? 'active-thickline' : '']"
            ></div>
            <div :class="[counter >= 3 ? 'active-thickline' : '']"></div>
            <div :class="[counter >= 4 ? 'active-thickline' : '']"></div>
            <div :class="[counter >= 5 ? 'active-thickline' : '']"></div>
            <div :class="[counter >= 6 ? 'active-thickline' : '']"></div>
            <div :class="[counter >= 7 ? 'active-thickline' : '']"></div>
            <div :class="[counter >= 8 ? 'active-thickline' : '']"></div>
            <div :class="[counter >= 9 ? 'active-thickline' : '']"></div>
            <div :class="[counter === 10 ? 'active-thickline' : '']"></div>
            <small>{{ counter }}/10</small>
          </div>
        </div>
        <div class="submitted-data">
          <div class="d-flex justify-content-between mb-3">
            <div class="check-data">
              <span :class="[biodata ? 'green' : 'red']"
                ><i :class="[biodata ? 'fa fa-check' : 'fa fa-times']"></i
              ></span>
              <small @click="routeBASE(1)">Biodata</small>
            </div>
            <p
              class=""
              :class="[
                detailsProps.rentFinancingObject.biodata_verified == 'YES'
                  ? 'verified'
                  : 'unverified'
              ]"
            >
              <span
                v-if="
                  detailsProps.rentFinancingObject.biodata_verified == 'YES'
                "
                >Verfied</span
              >
              <span v-else>Unverified</span>
            </p>
          </div>
          <div class="d-flex justify-content-between mb-3">
            <div class="check-data">
              <span :class="[occupationdata ? 'green' : 'red']"
                ><i
                  :class="[occupationdata ? 'fa fa-check' : 'fa fa-times']"
                ></i
              ></span>
              <small @click="routeBASE(2)">Occupation</small>
            </div>
            <p
              class=""
              :class="[
                detailsProps.rentFinancingObject.occupation_biodata == 'YES'
                  ? 'verified'
                  : 'unverified'
              ]"
            >
              <span
                v-if="
                  detailsProps.rentFinancingObject.occupation_biodata == 'YES'
                "
                >Verfied</span
              >
              <span v-else>Unverified</span>
            </p>
          </div>
          <div class="d-flex justify-content-between mb-3">
            <div class="check-data">
              <span :class="[hasGuarantor ? 'green' : 'red']"
                ><i :class="[hasGuarantor ? 'fa fa-check' : 'fa fa-times']"></i
              ></span>
              <small @click="trigerGuarantorModal(detailsProps.id)">Guarantor</small>
            </div>
            <p
              class=""
              :class="[
                detailsProps.rentFinancingObject.guarantor_verified == 'YES'
                  ? 'verified'
                  : 'unverified'
              ]"
            >
              <span
                v-if="
                  detailsProps.rentFinancingObject.guarantor_verified == 'YES'
                "
                >Verfied</span
              >
              <span v-else>Unverfied</span>
            </p>
          </div>
          <div class="d-flex justify-content-between mb-3">
            <div class="check-data">
              <span :class="[meansOfIds ? 'green' : 'red']"
                ><i :class="[meansOfIds ? 'fa fa-check' : 'fa fa-times']"></i
              ></span>
              <small @click="routeBASE(4)">Means of ID</small>
            </div>
            <p
              class=""
              :class="[
                detailsProps.rentFinancingObject.means_of_id_verified == 'YES'
                  ? 'verified'
                  : 'unverified'
              ]"
            >
              <span
                v-if="
                  detailsProps.rentFinancingObject.means_of_id_verified == 'YES'
                "
                >Verfied</span
              >
              <span v-else>Unverfied</span>
            </p>
          </div>
          <div class="d-flex justify-content-between mb-3">
            <div class="check-data">
              <span :class="[hasLandlord ? 'green' : 'red']"
                ><i :class="[hasLandlord ? 'fa fa-check' : 'fa fa-times']"></i
              ></span>
              <small @click="routeBASE(5)">Landlord Details</small>
            </div>
            <p
              class=""
              :class="[
                detailsProps.rentFinancingObject.landlord_details_verified ==
                'YES'
                  ? 'verified'
                  : 'unverified'
              ]"
            >
              <span
                v-if="
                  detailsProps.rentFinancingObject.landlord_details_verified ==
                    'YES'
                "
                >Verfied</span
              >
              <span v-else>Unverfied</span>
            </p>
          </div>
          <div class="d-flex justify-content-between mb-3">
            <div class="check-data">
              <span :class="[hasLandlordAccount ? 'green' : 'red']"
                ><i
                  :class="[hasLandlordAccount ? 'fa fa-check' : 'fa fa-times']"
                ></i
              ></span>
              <small @click="routeBASE(5)">Landlord's Account</small>
            </div>
            <p
              class=""
              :class="[
                detailsProps.rentFinancingObject.accountverified == 'YES'
                  ? 'verified'
                  : 'unverified'
              ]"
            >
              <span
                v-if="detailsProps.rentFinancingObject.accountverified == 'YES'"
                >Verfied</span
              >
              <span v-else>Unverified</span>
            </p>
          </div>
          <div class="d-flex justify-content-between mb-3">
            <div class="check-data">
              <span
                :class="[detailsProps.proofofaddress != null ? 'green' : 'red']"
                ><i
                  :class="[
                    detailsProps.proofofaddress != null
                      ? 'fa fa-check'
                      : 'fa fa-times'
                  ]"
                ></i
              ></span>
              <small @click="routeBASE(6)">Proof of Address</small>
            </div>

            <p
              class=""
              :class="[
                detailsProps.rentFinancingObject.proof_of_address_verified ==
                'YES'
                  ? 'verified'
                  : 'unverified'
              ]"
            >
              <span
                v-if="
                  detailsProps.rentFinancingObject.proof_of_address_verified ==
                    'YES'
                "
                >Verfied</span
              >
              <span v-else>Unverified</span>
            </p>
          </div>
          <div class="d-flex justify-content-between mb-3">
            <div class="check-data">
              <span
                :class="[detailsProps.rentFinancingObject.previouspayment != null ? 'green' : 'red']"
                ><i
                  :class="[
                    detailsProps.rentFinancingObject.previouspayment != null
                      ? 'fa fa-check'
                      : 'fa fa-times'
                  ]"
                ></i
              ></span>
              <small @click="routeBASE(7)">Last Payment Receipt</small>
            </div>

            <p
              class=""
              :class="[
                detailsProps.rentFinancingObject.proof_of_address_verified ==
                'YES'
                  ? 'verified'
                  : 'unverified'
              ]"
            >
              <span
                v-if="
                  detailsProps.rentFinancingObject.proof_of_address_verified ==
                    'YES'
                "
                >Verfied</span
              >
              <span v-else>Unverified</span>
            </p>
          </div>
          <div class="d-flex justify-content-between mb-3">
            <div class="check-data">
              <span
                :class="[detailsProps.tenancyagreement != null ? 'green' : 'red']"
                ><i
                  :class="[
                    detailsProps.tenancyagreement != null
                      ? 'fa fa-check'
                      : 'fa fa-times'
                  ]"
                ></i
              ></span>
              <small @click="routeBASE(8)">Tenancy agreement</small>
            </div>

            <p
              class=""
              :class="[
                detailsProps.rentFinancingObject.tenancy_agreement_verified ==
                'YES'
                  ? 'verified'
                  : 'unverified'
              ]"
            >
              <span
                v-if="
                  detailsProps.rentFinancingObject.tenancy_agreement_verified ==
                    'YES'
                "
                >Verfied</span
              >
              <span v-else>Unverified</span>
            </p>
          </div>
          <div class="d-flex justify-content-between mb-3">
            <div class="check-data">
              <span :class="[userData.subscribed == 'YES' ? 'green' : 'red']"
                ><i
                  :class="[
                    userData.subscribed == 'YES' ? 'fa fa-check' : 'fa fa-times'
                  ]"
                ></i
              ></span>
              <small @click="membership()" style="cursor: pointer;"
                >Subscribed</small
              >
            </div>

            <p
              class=""
              :class="[
                userData.subscribed == 'YES' ? 'verified' : 'unverified'
              ]"
            >
              <span v-if="userData.subscribed == 'YES'">Verfied</span>
              <span v-else>Unverified</span>
            </p>
          </div>
        </div>
      </div>

      <div class="property-details-full">
        <div class="details-header">
          <h4>Property details</h4>
        </div>
        <div class="details-data">
          <div class="">
            <div class="row row-details">
              <div class="col-md-4 pr-lg-0">
                <div class="content">
                  <h6>Annual amount</h6>
                  <h5>₦{{ Number(detailsProps.price).toLocaleString() }}</h5>
                </div>
                <!-- <hr> -->
              </div>
              <div class="col-md-4 pl-lg-0 pr-lg-0">
                <div class="content">
                  <h6>Property pin</h6>
                  <h5>{{ detailsProps.pin }}</h5>
                </div>
              </div>
              <div class="col-md-4 pl-lg-0">
                <div class="content">
                  <h6>Current rent due date</h6>
                  <h5>{{ detailsProps.rentFinancingObject.dateofleaseorrent | moment }}</h5>
                </div>
              </div>

              <div class="col-md-4 pr-lg-0" v-if="detailsProps.type == '1'">
                <div class="content">
                  <h6>Bedrooms</h6>
                  <h5 v-if="detailsProps.bedrooms">
                    {{ detailsProps.bedrooms }}
                  </h5>
                  <h5 v-if="!detailsProps.bedrooms">None</h5>
                </div>
              </div>
              <div class="col-md-4 pl-lg-0 pr-lg-0">
                <div class="content">
                  <h6>Property type</h6>
                  <h5 v-if="detailsProps.type == '1'">Redidential</h5>
                  <h5 v-if="detailsProps.type == '2'">Commercial</h5>
                </div>
              </div>
              <div class="col-md-4 pl-lg-0">
                <div class="content">
                  <h6>Property subtype</h6>
                  <h5>{{ detailsProps.subtypename }}</h5>
                </div>
              </div>
              <div class="col payment-col">
                <div class="content">
                  <h6>Payment plan</h6>
                  <h5
                    v-if="
                      detailsProps.rentFinancingObject.tenantmodeofpaymentid ==
                        '2'
                    "
                  >
                    Bi-annually
                  </h5>
                  <h5
                    v-if="
                      detailsProps.rentFinancingObject.tenantmodeofpaymentid ==
                        '3'
                    "
                  >
                    Quaterly
                  </h5>
                  <h5
                    v-if="
                      detailsProps.rentFinancingObject.tenantmodeofpaymentid ==
                        '4'
                    "
                  >
                    Monthly
                  </h5>
                  <p
                    v-if="
                      (detailsProps.rentfinance === 'YES' &&
                        detailsProps.approved === 'NO') ||
                        detailsProps.hasaccountdetails === 'NO'
                    "
                    @click="moveToEditProperty"
                  >
                    Edit
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <guarantorModal
    @action="dataFromGuarantor($event)"
    ></guarantorModal>
  </div>
</template>

<script>
import PageLoader from "./PageLoader.vue";
import { Service } from "../store/service";
import guarantorModal from "../components/GuarantorModal.vue";
import moment from "moment";

const Api = new Service();
export default {
  name: "RentedPropertyDetails",
  // props: {
  //   properties
  // },

  components: {
    PageLoader,
    guarantorModal
  },
  data() {
    return {
      detailsProps: "",
      displayDays: 0,
      displayMinutes: 0,
      displayHours: 0,
      displaySeconds: 0,
      nextThreeDays: "",
      serverDateTime: "",
      occupationdata: false,
      biodata: false,
      hasLandlord: false,
      hasLandlordAccount: false,
      hasGuarantor: false,
      meansOfIds: false,
      proofOfAddress: false,
      counter: 0,
      userData: ""
    };
  },
  // ready() {
  //   this.showRemaining();
  // },
  //   created() {
  //     this.$root.$refs.PropertyManagementAgreement = this === "Property Management Agreement";
  //   },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
    _days() {
      return this._hours * 24;
    },
    end() {
      return new Date(
        this.year,
        this.month,
        this.date,
        this.hour,
        this.minutes,
        this.seconds,
        this.milliseconds
        // 2020, 2, 15, 10, 10, 10,10
      );
    }
  },
  mounted() {
    // this.getServerDateTime();
    this.detailsProps = Api.getPropertyDetails();
    // console.log("Hello property", this.detailsProps);
    if (this.detailsProps.id === undefined || this.detailsProps.id === null) {
      this.$router.push({
        path: `/dashboard/rented`
      });
    } else {
      const appliedDate = new Date(
        this.detailsProps.rentFinancingObject.applied_date
      );
      // console.log("applied date", appliedDate);
      this.nextThreeDays = new Date(
        appliedDate.setDate(appliedDate.getDate() + 3)
      );
      // console.log("New three days", this.nextThreeDays);

      if (
        this.detailsProps.rentFinancingObject.approvedforrentfinancing == "0"
      ) {
        this.showRemaining();
      }
      this.usercompleteprofile();
    }
  },
  methods: {
    backTo() {
      this.$router.push({
        path: `/dashboard/rented`
      });
    },
    routeBASE(route) {
      if (route == 1 || route == 2 || route == 3 || route == 4) {
        this.$router.push({
          path: `/dashboard/biodata`
        });
      }
      if (route == 5) {
        // console.log("Prp details", this.detailsProps);
        if (
          this.detailsProps.hasaccountdetails === "NO" ||
          this.detailsProps.haslandlord === "NO" &&
          this.detailsProps.rentfinance === "YES" &&
          this.detailsProps.approved === "NO"
        ) {
          Api.pushPropertyDetails(this.detailsProps);
          this.$router.push({
            path: `/dashboard/addlandlord`
          });
        }
      }
      if (route == 6) {
        if (
          (this.detailsProps.rentfinance === "YES" &&
            this.detailsProps.approved === "NO") ||
          this.detailsProps.rentFinancingObject.previouspayment === "NO"
        ) {
          Api.pushPropertyDetails(this.detailsProps);
          this.$router.push({
            path: `/editpropertytenant`
          });
        }
      }
      if (route == 7) {
        if (
          (this.detailsProps.rentfinance === "YES" &&
            this.detailsProps.approved === "NO") ||
          this.detailsProps.hasaccountdetails === "NO"
        ) {
          Api.pushPropertyDetails(this.detailsProps);
          this.$router.push({
            path: `/editpropertytenant`
          });
        }
      }
      if (route == 8) {
        if (
          (this.detailsProps.rentfinance === "YES" &&
            this.detailsProps.approved === "NO") ||
          this.detailsProps.tenancyagreement === "NO"
        ) {
          Api.pushPropertyDetails(this.detailsProps);
          this.$router.push({
            path: `/dashboard/addlandlord`
          });
        }
      }
    },
    trigerGuarantorModal(id) {
      if (this.detailsProps.rentFinancingObject.guarantor_verified == "NO") {
        this.$root.$refs.Guarantor.openGuarantorModal(id);
      }
    },
    moveToEditProperty() {
      Api.pushPropertyDetails(this.detailsProps);
      this.$router.push({
        path: `/editpropertytenant`
      });
    },
    membership() {
      if (this.userData.subscribed == "NO") {
        return this.$root.$refs.D.openSubModal();
      }
    },
    async usercompleteprofile() {
      // console.log('Property ID', id);
      const id = JSON.parse(localStorage.getItem("shelta-user")).id;
      try {
        await Api.getRequest(`getusercompleteprofile/${id}`)
          .then(res => {
            // console.log("Complet profile count", res.data);
            this.fulldata = res.data.fulldata;
            this.profileData = res.data;
            this.userData = res.data.user_object;
            const userobject = res.data.user_object;
            const occupationobject = res.data;
            if (userobject.subscribed == "YES") {
              this.counter++;
            }
            if (
              userobject.phone != null &&
              userobject.address != null &&
              userobject.stateid != null &&
              userobject.cityid != null
            ) {
              this.biodata = true;
              this.counter++;
            }
            if (
              occupationobject.selfemployed_details != null ||
              (occupationobject.employeddetails != null &&
                userobject.occupationid != null)
            ) {
              this.occupationdata = true;
              this.counter++;
            }
            if (this.detailsProps.haslandlord == "YES") {
              this.hasLandlord = true;
              this.counter++;
            }
            if (this.detailsProps.hasaccountdetails == "YES") {
              this.hasLandlordAccount = true;
              this.counter++;
            }
            if (this.profileData.gurantor != null) {
              this.hasGuarantor = true;
              this.counter++;
            }
            if (
              userobject.meansofidfile != null &&
              userobject.meansofidtype != null
            ) {
              this.meansOfIds = true;
              this.counter++;
            }
            if (this.detailsProps.proofofaddress != null) {
              this.counter++;
            }
            if (this.detailsProps.rentFinancingObject.previouspayment != null) {
              this.counter++;
            }
            if (this.detailsProps.tenancyagreement != null) {
              this.counter++;
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getServerDateTime() {
      Api.getRequest(`getservertime`).then(res => {
        // console.log("Server time", new Date(moment(res.data.date)));
        this.serverDateTime = new Date(moment(res.data.date));
      });
    },
    formartNum: num => (num < 10 ? "0" + num : num),
    showRemaining() {
      const timer = setInterval(() => {
        const now = new Date();
        // const now = new Date(this.detailsProps.rentFinancingObject.applied_date);
        const end = this.nextThreeDays;
        const distance = end.getTime() - now.getTime();

        if (distance < 0) {
          clearInterval(timer);
          return;
        }

        const days = Math.floor(distance / this._days);
        // console.log("days", days);
        const hours = Math.floor((distance % this._days) / this._hours);
        const minutes = Math.floor((distance % this._hours) / this._minutes);
        const seconds = Math.floor((distance % this._minutes) / this._seconds);

        this.displayMinutes = minutes < 10 ? "0" + minutes : minutes;
        this.displaySeconds = this.formartNum(seconds);
        this.displayHours = hours < 10 ? "0" + hours : hours;
        this.displayDays = days < 10 ? "0" + days : days;
      }, 1000);
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-bold-Italic";
  src: local("Lato-BlackItalic"),
    url("../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-thin-Italic";
  src: local("Lato-ThinItalic"),
    url("../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
}

$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$black: #000000;
$shelta-black: #000a2f;
$black-2: #262626;
$shelta-gray: #555c74;
$shelta-brown: #553b00;
$shelta-warning: #fff7e6;
$shelta-off-white: #f9fafb;
$shelta-grey-2: #57595a;
$dline: #dddddd;
$off-grey: #f9f9f9;
$disablled-input: #f8fafc;
$e7: #e7e7e7;
$off-white-2: #e2e8f0;
$shelta-green: #2bc300;
$off-green: #e7faf1;
$shelta-red: #ea0033;
$off-red: #ffecf0;

.details-holder {
  margin: 40px 15px;
  padding: 40px 24px;
  border-radius: 15px;
  background: $white;
}

.back-arrow {
  display: flex;
  span {
    padding-left: 8px;
    color: $shelta-black;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }
}

.property-info {
  padding-top: 32px;
}

.prop-details {
  text-align: left;
  h4,
  h6 {
    color: $shelta-black;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    margin-bottom: 0;
  }
  h4 {
    font-size: 32px;
  }
  h6 {
    font-size: 24px;
    margin-bottom: 5px;
  }
  h3 {
    color: $primary;
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 0;
  }
  small {
    color: $shelta-gray;
    font-family: Lato;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    line-height: 20px;
  }
  @media only screen and (max-width: 599px) {
    h4 {
      font-size: 25px;
      padding-top: 10px;
    }
    h3 {
      font-size: 23px;
    }
  }
}

.countdown {
  text-align: right;
  h4 {
    color: $shelta-black;
    // text-align: right;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  small {
    margin-right: 4px;
    color: $shelta-gray;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    border-radius: 4px;
    background: #f9f9f9;
    padding: 8px;
    font-size: 16px;
  }
  span {
    color: $shelta-black;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-right: 4px;
  }
}
@media only screen and (max-width: 599px) {
  .top-row {
    flex-direction: column-reverse;
  }
  .countdown {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
  }
}

.financing-header {
  margin-top: 32px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px 16px 0px 0px;
  border: 1px solid $off-white-2;
  background: $off-grey;
  h4 {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 599px) {
  .financing-header {
    display: block;
    padding: 16px 10px;
  }
}

.thick-line {
  display: flex;
  align-items: center;
  div {
    width: 24px;
    height: 4px;
    background: #d9d9d9;
    margin: 0 6px;
  }
  small {
    color: #aaadba;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-left: 16px;
    // line-height: 150%;
  }
}
.active-thickline {
  background: $shelta-green !important;
}

.submitted-data {
  border-radius: 0px 0px 16px 16px;
  border: 1px solid $off-white-2;
  background: $white;
  padding: 24px 16px;
}
@media only screen and (max-width: 599px) {
  .submitted-data {
    padding: 24px 5px;
  }
}

.check-data {
  // margin-bottom: 16px;
  // span {
  //   // background: $shelta-green;
  //   color: $white;
  //   padding: 2px;
  //   margin-left: 10px;
  //   margin-right: 10px;
  //   // i {
  //   //   font-size: 12px;
  //   // }
  // }
  small {
    cursor: pointer;
  }
}
@media only screen and (max-width: 599px) {
  .check-data {
    small {
      text-align: left;
      cursor: pointer;
    }
  }
}
.cancel {
  // margin-bottom: 16px;
  span {
    background: $shelta-red;
    color: $white;
    padding: 2px 3.5px;
    margin-left: 10px;
    margin-right: 10px;
    // i {
    //   font-size: 12px;
    // }
  }
}
.green {
  background: $shelta-green;
  color: $white;
  padding: 2px;
  margin-left: 10px;
  margin-right: 10px;
}
.red {
  background: $shelta-red;
  color: $white;
  padding: 2px 3.5px;
  margin-left: 10px;
  margin-right: 10px;
}
.verified {
  background: $off-green;
  color: $shelta-green;
}
.unverified {
  background: $off-red;
  color: $shelta-red;
}
.pending {
  color: $shelta-gray;
  background: #fafafa;
}
.verified,
.pending,
.unverified {
  border-radius: 4px;
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  padding: 4px 8px;
  // margin-bottom: 0;
}

// .property-details-full {

// }
.details-data {
  padding: 0 24px 24px;
  border-radius: 0px 0px 16px 16px;
  border: 1px solid #e2e8f0;
  background: #ffffff;
  // padding: 24px 16px;
}
.details-header {
  margin-top: 32px;
  padding: 16px 24px;
  text-align: left;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  border-radius: 16px 16px 0px 0px;
  border: 1px solid $off-white-2;
  background: $off-grey;
  h4 {
    margin-bottom: 0;
  }
}

.row-details {
  .col-md-4:nth-child(-n + 3) {
    margin-top: 24px;
  }
}
.row-details {
  .col-md-4 {
    margin-top: 32px;
  }
  .col-md-4:after {
    content: "";
    display: block;
    border-bottom: 1px solid $off-white-2;
  }
  @media only screen and (max-width: 599px) {
    .col-md-4:after {
      border-bottom: none;
    }
  }
}
.content {
  text-align: left;
  h6 {
    color: $shelta-gray;
    font-family: Lato;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 16px;
  }
  h5 {
    color: $shelta-black;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 32px;
  }
  @media only screen and (max-width: 599px) {
    h5 {
      margin-bottom: 16px;
    }
  }
}

.payment-col {
  margin-top: 32px;
  h6 {
    color: $shelta-gray;
    font-family: Lato;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 16px;
  }
  h5 {
    color: $shelta-black;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
  p {
    color: $primary;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    cursor: pointer;
  }
}
</style>
