var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.detailsProps.id)?_c('div',[_c('PageLoader')],1):_c('div',{staticClass:"details-holder"},[_c('div',{staticClass:"back-arrow",staticStyle:{"cursor":"pointer"}},[_c('img',{attrs:{"src":require("../assets/images/thickbackarrow.svg"),"alt":"back"},on:{"click":_vm.backTo}}),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.backTo}},[_vm._v("Property")])]),_c('div',{staticClass:"property-info"},[_c('div',{staticClass:"row top-row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-12 col-12"},[_c('div',[(_vm.detailsProps.oneimageurl == null || _vm.detailsProps.oneimageurl == '')?_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../assets/images/property-image-placeholder.svg"),"alt":"property"}}):_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.detailsProps.oneimageurl,"alt":"property"}})])]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"prop-details"},[_c('h4',[_vm._v(_vm._s(_vm.detailsProps.title))]),_c('h6',[_vm._v(_vm._s(_vm.detailsProps.city)+" "+_vm._s(_vm.detailsProps.state))]),_c('h3',[_vm._v(" ₦"+_vm._s(Number(_vm.detailsProps.price).toLocaleString())+"/Annually ")]),_c('small',[_vm._v("Rent financing application in view")])])])])]),(
            _vm.detailsProps.rentFinancingObject.applied_date != null &&
              _vm.detailsProps.rentFinancingObject.approvedforrentfinancing == '0'
          )?_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"countdown"},[_c('h4',[_vm._v("Verification Countdown")]),_c('small',[_vm._v(_vm._s(_vm.displayDays))]),_c('span',[_vm._v("Day"),(_vm.displayDays > 1)?_c('span',[_vm._v("s")]):_vm._e()]),_c('small',[_vm._v(_vm._s(_vm.displayHours))]),_c('span',[_vm._v("Hour"),(_vm.displayHours > 1)?_c('span',[_vm._v("s")]):_vm._e()]),_c('small',[_vm._v(_vm._s(_vm.displayMinutes))]),_c('span',[_vm._v("Min"),(_vm.displayMinutes > 1)?_c('span',[_vm._v("s")]):_vm._e()]),_c('small',[_vm._v(_vm._s(_vm.displaySeconds))]),_c('span',[_vm._v("Sec")])])]):_vm._e()])]),(_vm.detailsProps.rentFinancingObject.applied_date != null)?_c('div',{staticClass:"financing-documents-submition"},[_c('div',{staticClass:"financing-header"},[_c('h4',[_vm._v("Rent Financing")]),_c('div',{staticClass:"thick-line"},[_c('div',{class:[_vm.counter >= 1 ? 'active-thickline' : '']}),_c('div',{class:[_vm.counter >= 2 ? 'active-thickline' : '']}),_c('div',{class:[_vm.counter >= 3 ? 'active-thickline' : '']}),_c('div',{class:[_vm.counter >= 4 ? 'active-thickline' : '']}),_c('div',{class:[_vm.counter >= 5 ? 'active-thickline' : '']}),_c('div',{class:[_vm.counter >= 6 ? 'active-thickline' : '']}),_c('div',{class:[_vm.counter >= 7 ? 'active-thickline' : '']}),_c('div',{class:[_vm.counter >= 8 ? 'active-thickline' : '']}),_c('div',{class:[_vm.counter >= 9 ? 'active-thickline' : '']}),_c('div',{class:[_vm.counter === 10 ? 'active-thickline' : '']}),_c('small',[_vm._v(_vm._s(_vm.counter)+"/10")])])]),_c('div',{staticClass:"submitted-data"},[_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('div',{staticClass:"check-data"},[_c('span',{class:[_vm.biodata ? 'green' : 'red']},[_c('i',{class:[_vm.biodata ? 'fa fa-check' : 'fa fa-times']})]),_c('small',{on:{"click":function($event){return _vm.routeBASE(1)}}},[_vm._v("Biodata")])]),_c('p',{class:[
              _vm.detailsProps.rentFinancingObject.biodata_verified == 'YES'
                ? 'verified'
                : 'unverified'
            ]},[(
                _vm.detailsProps.rentFinancingObject.biodata_verified == 'YES'
              )?_c('span',[_vm._v("Verfied")]):_c('span',[_vm._v("Unverified")])])]),_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('div',{staticClass:"check-data"},[_c('span',{class:[_vm.occupationdata ? 'green' : 'red']},[_c('i',{class:[_vm.occupationdata ? 'fa fa-check' : 'fa fa-times']})]),_c('small',{on:{"click":function($event){return _vm.routeBASE(2)}}},[_vm._v("Occupation")])]),_c('p',{class:[
              _vm.detailsProps.rentFinancingObject.occupation_biodata == 'YES'
                ? 'verified'
                : 'unverified'
            ]},[(
                _vm.detailsProps.rentFinancingObject.occupation_biodata == 'YES'
              )?_c('span',[_vm._v("Verfied")]):_c('span',[_vm._v("Unverified")])])]),_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('div',{staticClass:"check-data"},[_c('span',{class:[_vm.hasGuarantor ? 'green' : 'red']},[_c('i',{class:[_vm.hasGuarantor ? 'fa fa-check' : 'fa fa-times']})]),_c('small',{on:{"click":function($event){return _vm.trigerGuarantorModal(_vm.detailsProps.id)}}},[_vm._v("Guarantor")])]),_c('p',{class:[
              _vm.detailsProps.rentFinancingObject.guarantor_verified == 'YES'
                ? 'verified'
                : 'unverified'
            ]},[(
                _vm.detailsProps.rentFinancingObject.guarantor_verified == 'YES'
              )?_c('span',[_vm._v("Verfied")]):_c('span',[_vm._v("Unverfied")])])]),_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('div',{staticClass:"check-data"},[_c('span',{class:[_vm.meansOfIds ? 'green' : 'red']},[_c('i',{class:[_vm.meansOfIds ? 'fa fa-check' : 'fa fa-times']})]),_c('small',{on:{"click":function($event){return _vm.routeBASE(4)}}},[_vm._v("Means of ID")])]),_c('p',{class:[
              _vm.detailsProps.rentFinancingObject.means_of_id_verified == 'YES'
                ? 'verified'
                : 'unverified'
            ]},[(
                _vm.detailsProps.rentFinancingObject.means_of_id_verified == 'YES'
              )?_c('span',[_vm._v("Verfied")]):_c('span',[_vm._v("Unverfied")])])]),_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('div',{staticClass:"check-data"},[_c('span',{class:[_vm.hasLandlord ? 'green' : 'red']},[_c('i',{class:[_vm.hasLandlord ? 'fa fa-check' : 'fa fa-times']})]),_c('small',{on:{"click":function($event){return _vm.routeBASE(5)}}},[_vm._v("Landlord Details")])]),_c('p',{class:[
              _vm.detailsProps.rentFinancingObject.landlord_details_verified ==
              'YES'
                ? 'verified'
                : 'unverified'
            ]},[(
                _vm.detailsProps.rentFinancingObject.landlord_details_verified ==
                  'YES'
              )?_c('span',[_vm._v("Verfied")]):_c('span',[_vm._v("Unverfied")])])]),_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('div',{staticClass:"check-data"},[_c('span',{class:[_vm.hasLandlordAccount ? 'green' : 'red']},[_c('i',{class:[_vm.hasLandlordAccount ? 'fa fa-check' : 'fa fa-times']})]),_c('small',{on:{"click":function($event){return _vm.routeBASE(5)}}},[_vm._v("Landlord's Account")])]),_c('p',{class:[
              _vm.detailsProps.rentFinancingObject.accountverified == 'YES'
                ? 'verified'
                : 'unverified'
            ]},[(_vm.detailsProps.rentFinancingObject.accountverified == 'YES')?_c('span',[_vm._v("Verfied")]):_c('span',[_vm._v("Unverified")])])]),_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('div',{staticClass:"check-data"},[_c('span',{class:[_vm.detailsProps.proofofaddress != null ? 'green' : 'red']},[_c('i',{class:[
                  _vm.detailsProps.proofofaddress != null
                    ? 'fa fa-check'
                    : 'fa fa-times'
                ]})]),_c('small',{on:{"click":function($event){return _vm.routeBASE(6)}}},[_vm._v("Proof of Address")])]),_c('p',{class:[
              _vm.detailsProps.rentFinancingObject.proof_of_address_verified ==
              'YES'
                ? 'verified'
                : 'unverified'
            ]},[(
                _vm.detailsProps.rentFinancingObject.proof_of_address_verified ==
                  'YES'
              )?_c('span',[_vm._v("Verfied")]):_c('span',[_vm._v("Unverified")])])]),_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('div',{staticClass:"check-data"},[_c('span',{class:[_vm.detailsProps.rentFinancingObject.previouspayment != null ? 'green' : 'red']},[_c('i',{class:[
                  _vm.detailsProps.rentFinancingObject.previouspayment != null
                    ? 'fa fa-check'
                    : 'fa fa-times'
                ]})]),_c('small',{on:{"click":function($event){return _vm.routeBASE(7)}}},[_vm._v("Last Payment Receipt")])]),_c('p',{class:[
              _vm.detailsProps.rentFinancingObject.proof_of_address_verified ==
              'YES'
                ? 'verified'
                : 'unverified'
            ]},[(
                _vm.detailsProps.rentFinancingObject.proof_of_address_verified ==
                  'YES'
              )?_c('span',[_vm._v("Verfied")]):_c('span',[_vm._v("Unverified")])])]),_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('div',{staticClass:"check-data"},[_c('span',{class:[_vm.detailsProps.tenancyagreement != null ? 'green' : 'red']},[_c('i',{class:[
                  _vm.detailsProps.tenancyagreement != null
                    ? 'fa fa-check'
                    : 'fa fa-times'
                ]})]),_c('small',{on:{"click":function($event){return _vm.routeBASE(8)}}},[_vm._v("Tenancy agreement")])]),_c('p',{class:[
              _vm.detailsProps.rentFinancingObject.tenancy_agreement_verified ==
              'YES'
                ? 'verified'
                : 'unverified'
            ]},[(
                _vm.detailsProps.rentFinancingObject.tenancy_agreement_verified ==
                  'YES'
              )?_c('span',[_vm._v("Verfied")]):_c('span',[_vm._v("Unverified")])])]),_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('div',{staticClass:"check-data"},[_c('span',{class:[_vm.userData.subscribed == 'YES' ? 'green' : 'red']},[_c('i',{class:[
                  _vm.userData.subscribed == 'YES' ? 'fa fa-check' : 'fa fa-times'
                ]})]),_c('small',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.membership()}}},[_vm._v("Subscribed")])]),_c('p',{class:[
              _vm.userData.subscribed == 'YES' ? 'verified' : 'unverified'
            ]},[(_vm.userData.subscribed == 'YES')?_c('span',[_vm._v("Verfied")]):_c('span',[_vm._v("Unverified")])])])])]):_vm._e(),_c('div',{staticClass:"property-details-full"},[_vm._m(0),_c('div',{staticClass:"details-data"},[_c('div',{},[_c('div',{staticClass:"row row-details"},[_c('div',{staticClass:"col-md-4 pr-lg-0"},[_c('div',{staticClass:"content"},[_c('h6',[_vm._v("Annual amount")]),_c('h5',[_vm._v("₦"+_vm._s(Number(_vm.detailsProps.price).toLocaleString()))])])]),_c('div',{staticClass:"col-md-4 pl-lg-0 pr-lg-0"},[_c('div',{staticClass:"content"},[_c('h6',[_vm._v("Property pin")]),_c('h5',[_vm._v(_vm._s(_vm.detailsProps.pin))])])]),_c('div',{staticClass:"col-md-4 pl-lg-0"},[_c('div',{staticClass:"content"},[_c('h6',[_vm._v("Current rent due date")]),_c('h5',[_vm._v(_vm._s(_vm._f("moment")(_vm.detailsProps.rentFinancingObject.dateofleaseorrent)))])])]),(_vm.detailsProps.type == '1')?_c('div',{staticClass:"col-md-4 pr-lg-0"},[_c('div',{staticClass:"content"},[_c('h6',[_vm._v("Bedrooms")]),(_vm.detailsProps.bedrooms)?_c('h5',[_vm._v(" "+_vm._s(_vm.detailsProps.bedrooms)+" ")]):_vm._e(),(!_vm.detailsProps.bedrooms)?_c('h5',[_vm._v("None")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"col-md-4 pl-lg-0 pr-lg-0"},[_c('div',{staticClass:"content"},[_c('h6',[_vm._v("Property type")]),(_vm.detailsProps.type == '1')?_c('h5',[_vm._v("Redidential")]):_vm._e(),(_vm.detailsProps.type == '2')?_c('h5',[_vm._v("Commercial")]):_vm._e()])]),_c('div',{staticClass:"col-md-4 pl-lg-0"},[_c('div',{staticClass:"content"},[_c('h6',[_vm._v("Property subtype")]),_c('h5',[_vm._v(_vm._s(_vm.detailsProps.subtypename))])])]),_c('div',{staticClass:"col payment-col"},[_c('div',{staticClass:"content"},[_c('h6',[_vm._v("Payment plan")]),(
                    _vm.detailsProps.rentFinancingObject.tenantmodeofpaymentid ==
                      '2'
                  )?_c('h5',[_vm._v(" Bi-annually ")]):_vm._e(),(
                    _vm.detailsProps.rentFinancingObject.tenantmodeofpaymentid ==
                      '3'
                  )?_c('h5',[_vm._v(" Quaterly ")]):_vm._e(),(
                    _vm.detailsProps.rentFinancingObject.tenantmodeofpaymentid ==
                      '4'
                  )?_c('h5',[_vm._v(" Monthly ")]):_vm._e(),(
                    (_vm.detailsProps.rentfinance === 'YES' &&
                      _vm.detailsProps.approved === 'NO') ||
                      _vm.detailsProps.hasaccountdetails === 'NO'
                  )?_c('p',{on:{"click":_vm.moveToEditProperty}},[_vm._v(" Edit ")]):_vm._e()])])])])])])]),_c('guarantorModal',{on:{"action":function($event){return _vm.dataFromGuarantor($event)}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"details-header"},[_c('h4',[_vm._v("Property details")])])}]

export { render, staticRenderFns }